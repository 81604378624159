// src/components/Payment.tsx
import React, {useState} from 'react';
import '../styles/payment.css'; // Import payment CSS for styling

interface PaymentProps {
    onNavigate: (screen: string) => void; // Prop for navigation
    setPurchasedAudio:any;
}

const Payment: React.FC<PaymentProps> = ({onNavigate, setPurchasedAudio}) => {

    const handleButtonClick = (level: number) => {
        setPurchasedAudio(level);
        // Переключение на экран Confirm
        setTimeout(() => {
            onNavigate('Confirm'); // Переход на экран Confirm
        }, 0); // Задержка для демонстрации всплывающего сообщения
    };
    return (
        <div className='paymentInner'>
            <div className="paymentTitle">
                <span className='paymentTitleText'>ПЛАТЕЖИ</span>
            </div>
            <div className="paymentSubtitle">
                ДЛЯ <span className="paymentSubtitleBold">ОТКРЫТИЯ</span> ДОСТУПА К <span
                className="paymentSubtitleBold">НЕЙРОАКУСТИЧЕСКИМ ПРОГРАММАМ</span> И УЧАСТИЯ В БЛАГОТВОРИТЕЛЬНОЙ
                СИСТЕМЕ <span className="paymentSubtitleBold">ПЕРЕРАСПРЕДЕЛЕНИЯ</span> ДЕНЕГ, ВАМ <span
                className="paymentSubtitleBold">НЕОБХОДИМО</span> ОТКРЫТЬ ВСЕ ЧАСТИ:
            </div>
            <div className="paymentLots">
                {[...Array(7)].map((_, index) => {
                    const level = index + 1;
                    const cost = [5, 7, 10, 29, 39, 43, 56][index]; // Costs for each level
                    return (
                        <button key={level} className="paymentItem" onClick={() => handleButtonClick(level)}>
                            <div className="paymentLot">ОТКРЫТЬ ДОСТУП <span
                                className='paymentLotText'>{level} УРОВЕНЬ</span></div>
                            <div className="paymentCoast">{cost} USDT</div>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Payment;
