import React, {useEffect, useState} from 'react';
import '../styles/myprofile.css'

interface ProfileData {
    id: string;
    name: string;
    username: string;
    photoUrl: string;
    email:string;
    wallet:string;
    phone:string;
    openedLevels: number;
    invitedUsers: number;
    inviteDepth: any[];
}


interface ProfileProps{
    profile: any;
    setProfileForEdit:any;
    setCurrentScrren:any;
}


const MyProfile: React.FC<ProfileProps> = ({profile, setProfileForEdit, setCurrentScrren}) => {
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const telegram = window.Telegram.WebApp;
    const initDataUnsafe = telegram.initDataUnsafe;
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetch('https://otvy.ru/api/User/profile', {
                    method: 'GET',
                    headers: {
                        'accept': 'text/plain',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Ошибка: ${response.status}`);
                }

                const data = await response.json();
                setProfileData(data.data);// `data.data` — структура ответа.
                setProfileForEdit({firstName: data.data.name, lastName: '', phoneNumber: data.data.phone, email: data.data.email})
            } catch (error) {
                console.error('Ошибка при загрузке профиля:', error);
            }
        };

        fetchProfile();
    }, []);
    
    return (
        <div>
            {profileData ? (
                <div>
                    <div style={{width: "100%;"}} className="profile">
                        <div className="profileLogo">
                            <div className="profileLogoInner">
                                <svg
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <rect
                                        width="48"
                                        height="48"
                                        rx="2"
                                        fill="url(#pattern0_1_1111)"
                                    />
                                    <defs>
                                        <pattern
                                            id="pattern0_1_1111"
                                            patternContentUnits="objectBoundingBox"
                                            width="1"
                                            height="1"
                                        >
                                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                                        </pattern>
                                        <image
                                            id="image0_1_1111"
                                            width="200"
                                            height="200"
                                            xlinkHref={profileData.photoUrl}
                                        />
                                    </defs>
                                </svg>
                                <span>{profileData?.username || "@nousername"}</span>
                            </div>
                            <span style={{marginRight: "26px", color: "#FFEFBC"}}>СТАТУС</span>
                        </div>
                        <div className="profileUnder" style={{marginBottom:'30px'}}>
                        <div className="profileUnderRow">
                                <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    ИМЯ
                                    </span>
                                                    </div>
                                                    <div>
                                    <span 
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                    >
                                    {profileData.name}
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="profileUnderRow">
                                <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    НИКНЕЙМ
                                    </span>
                                                    </div>
                                                    <div>
                                    <span 
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                    >
                                    {profileData.username}
                                    </span>
                                                    </div>
                                                </div>
                            <div className="profileUnderRow">
                                <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    ДАТА РОЖДЕНИЯ
                                    </span>
                                                    </div>
                                                    <div>
                                    <span 
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)"
                                        }}
                                    >
                                    01.08.2022
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="profileUnderRow">
                                <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    НОМЕР ТЕЛЕФОНА
                                    </span>
                                                    </div>
                                                    <div>
                                    <span 
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)"
                                        }}
                                    >
                                    {profileData?.phone || "undefined"}
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="profileUnderRow">
                                                    <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    ВАШ EMAIL
                                    </span>
                                                    </div>
                                                    <div>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                    >
                                    {profileData?.email || "undefined"}
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="profileUnderRow">
                                <div>
                                    <span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginLeft: "23px",
                                        }}
                                    >
                                    КОШЕЛЕК
                                    </span>
                                                    </div>
                                                    <div>
                                    <span 
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "10px",
                                            marginRight: "23px",
                                            color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                    >
                                    {profileData?.wallet || "undefined"}
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="profileUnderRow">
                                <span
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "400",
                                        lineHeight: "10px",
                                        marginLeft: "23px",
                                    }}
                                >
                                    ЯЗЫК
                                </span>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight: "10px",
                                                            marginRight: "23px",
                                                            color: "rgba(255, 255, 255, 0.5)",
                                                        }}
                                                    >
                                    <span
                                        style={{color: "rgba(255, 255, 255, 1)"}}></span>🇷🇺   РУССКИЙ
                                </span>
                            </div>
                        </div>
                        <div style={{marginLeft:'23px', width:'100%'}}>
                            <button onClick={()=> setCurrentScrren("ProfileEdit")} style={{border:'none', borderRadius:'15px 15px 15px 15px', width:'60%', height:'50px', backgroundColor:'#5376FF'}}>
                                <span style={{color:'white', fontSize:'20px'}}>
                                    Редактировать
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Загрузка...</p>
            )}
        </div>
    );
};

export default MyProfile;
