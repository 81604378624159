import React, {useEffect, useState} from "react";
import DefaultImg from "../image/DefaultImg.svg";
import { confirmOrder } from "../../api/userApi";

interface OrderProps{
    purchasedAudio:number|null;
}

interface AudioData {
    index: number;
    audio: string;
    purchased: boolean;
    purchaseStatus: string;
    usernameToPurchase: string;
    walletToPurchase: string;
}

const OrderConfirmation: React.FC<OrderProps> = ({purchasedAudio}) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        language: "",
        email: "",
        nickname: "",
        paymentDate: "",
        screenshot: null as File | null,
    });

    const[audios, setAudios]=useState<AudioData[]>([]);
    
    const onSubmitData=async()=>
    {
        const response= await confirmOrder(formData);
        if (response.success)
        {
            alert("Форма была успешно отправлена!")
        }
        else 
        {
            alert(response.error);
        }
    }
    useEffect(()=>
    {
        const getAudioData = async()=>
        {
            try {
                const response = await fetch("https://otvy.ru/api/User/Audio", {
                    method: 'GET',
                    headers: {
                        Accept: "text/plain",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Ошибка загрузки данных");
                }

                const result = await response.json();


                // Сохраняем данные в состояние, если они есть
                if (result?.data) {
                    setAudios(result.data);
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
        }
        getAudioData();
    },[])

    const audioToPurchase=audios.find((audio)=> audio.index===purchasedAudio);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFormData((prev) => ({...prev, screenshot: e.target.files![0]}));
        }
    };

    const handleNext = () => {
        if (step < 5) setStep(step + 1);
    };

    const handlePrev = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleCopy=(text:string|undefined)=>
    {
        if (text===undefined)
        {
            return;
        }
        navigator.clipboard.writeText(text)
            .catch(_=>
            {
                return;
            })
    }

    const getPrice=(index:number|null)=>
    {
        if (index === 1)
        {
            return 5;
        }
        if (index===2)
        {
            return 7;
        }
        if (index===3)
        {
            return 10;
        }
        if (index===4)
        {
            return 29;
        }
        if (index===5)
        {
            return 39;
        }
        if (index===6)
        {
            return 43;
        }
        if (index===7)
        {
            return 56;
        }
        else return 0;
    }
    return (
        <div>
            <div className="firstScreenInner">
                {/* Верхняя информация остается на месте */}
                <div className={"special-for-me"}
                     style={{width: '359px', padding: '20px', marginTop: '30px', borderRadius: '10px'}}>
                    <p style={{
                        textAlign: "center",
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: '10px'
                    }}>Оформление доступа</p>
                    <p style={{marginBottom: '15px', fontWeight: '300'}}>Отправьте {getPrice(purchasedAudio)}USDT через бот @Wallet по юзернейму
                        пользователя или просто переведите через удобный
                        кошелек на адрес пользователя. <br/> Проверьте обязательно, чтобы сеть была TON.</p>
                    <p>
                        Нажмите на юзернейм либо кошелек, чтобы скопировать
                    </p>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '10px'}}>
                        <svg
                            style={{marginRight: '10px'}}
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <rect
                                width="48"
                                height="48"
                                rx="2"
                                fill="url(#pattern0_1_1111)"
                            />
                            <defs>
                                <pattern
                                    id="pattern0_1_1111"
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                    height="1"
                                >
                                    <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                                </pattern>
                                <image
                                    id="image0_1_1111"
                                    width="200"
                                    height="200"
                                    xlinkHref={DefaultImg}
                                />
                            </defs>
                        </svg>
                        <div style={{fontSize: '16px, '}}>
                            <p style={{fontWeight: 700}}>Johan Liebert</p>
                            <p style={{fontWeight: 400}}>Адрес кошелька: <span style={{cursor:'pointer'}} onClick={()=> handleCopy(audioToPurchase?.walletToPurchase)}>{audioToPurchase?.walletToPurchase.substring(0,9)}</span></p>
                            <p style={{fontWeight: 400}}>Юзернейм: <span style={{cursor:'pointer'}}  onClick={()=> handleCopy(audioToPurchase?.usernameToPurchase)}>{audioToPurchase?.usernameToPurchase}</span></p>
                        </div>
                    </div>
                </div>

                {/* Динамическая часть с шагами */}
                <div style={{marginTop: '40px'}} className="firstScreenUnder">
                    {step === 1 && (
                        <div>
                            <input
                                style={{paddingLeft: '20px', paddingRight: '20px'}}
                                type="email"
                                name="email"
                                placeholder={"ВАШ EMAIL"}
                                value={formData.email}
                                onChange={handleInputChange}
                                className="firstScreenUnderButton"
                            />
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <input
                                type="text"
                                style={{paddingLeft: '20px', paddingRight: '20px'}}
                                name="nickname"
                                placeholder={"Логин получателя @NICKNAME"}
                                value={formData.nickname}
                                onChange={handleInputChange}
                                className="firstScreenUnderButton"
                            />
                        </div>
                    )}
                    {step === 3 && (
                        <div style={{marginBottom:'-21px'}}>
                            <input
                                type="datetime-local"
                                style={{paddingLeft: '20px', paddingRight: '20px', color:'transparent'}}
                                name="paymentDate"
                                value={formData.paymentDate}
                                onChange={handleInputChange}
                                className="firstScreenUnderButton"
                            />
                            <label style={{position:'relative', top:'-38px', left:'20px'}}>
                             Время и дата платежа
                            </label>
                        </div>
                    )}
                    {step === 4 && (
                        <div>
                            <label htmlFor="screenshot" className="customFileLabel">
                                {formData.screenshot ? formData.screenshot.name : "Скриншот об оплате"}
                                <input
                                    type="file"
                                    id="screenshot"
                                    name="screenshot"
                                    onChange={handleFileChange}
                                    className="fileInput"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={22} fill="none">
                                    <path
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="m16.375 11.49-7.693 7.692a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3.001 3.001 0 0 1 17.5 6.122L6.552 17.07a1.5 1.5 0 0 1-2.112-2.13l7.81-7.81m-5.689 9.93-.01.01"
                                    />
                                </svg>
                            </label>
                        </div>

                    )}
                    <div className="firstScreenUnderButton_Down">
                        <span style={{marginLeft: "29px"}}>
                            ШАГ{" "}
                            <span
                                style={{fontSize: "16px", fontWeight: "700", lineHeight: "18px"}}
                            >
                                {step} ИЗ 4
                            </span>
                        </span>
                        <div style={{height:'95%'}}>
                            {step > 1 && (
                                <button style={{height: '50%', fontSize: '14px'}}
                                        className="view buttonBack" onClick={handlePrev}>
                                    НАЗАД
                                </button>
                            )}
                            {step < 4 && (
                                <button style={{height: step===1?'100%':'50%', fontSize: '14px'}} className="view buttonBack"
                                        onClick={handleNext}>
                                    ДАЛЕЕ
                                </button>
                            )}
                            {step === 4 && (
                                <button
                                    style={{height: '50%', fontSize: '14px'}}
                                    className="view buttonBack"
                                    onClick={() => onSubmitData()}
                                >
                                    ОТПРАВИТЬ
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmation;
