import '../styles/menu.css';

interface MenuProps {
    onNavigate: (screen: string) => void; 
}
const Menu: React.FC<MenuProps> = ({ onNavigate }) => {  
    return (
      <div className="menu">
        <div className="menu-item item-black" onClick={() => onNavigate("Home")} >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3812 8.875L16.75 7.24488V3.25C16.75 2.63125 16.2438 2.125 15.625 2.125H14.5C13.8812 2.125 13.375 2.63125 13.375 3.25V3.87212L11.125 1.62438C10.8179 1.33413 10.5366 1 10 1C9.46338 1 9.18213 1.33413 8.875 1.62438L1.61875 8.875C1.26775 9.24062 1 9.50725 1 10C1 10.6334 1.486 11.125 2.125 11.125H3.25V17.875C3.25 18.4938 3.75625 19 4.375 19H6.625C7.24632 19 7.75 18.4963 7.75 17.875V13.375C7.75 12.7562 8.25625 12.25 8.875 12.25H11.125C11.7438 12.25 12.25 12.7562 12.25 13.375V17.875C12.25 18.4963 12.1912 19 12.8125 19H15.625C16.2438 19 16.75 18.4938 16.75 17.875V11.125H17.875C18.514 11.125 19 10.6334 19 10C19 9.50725 18.7322 9.24062 18.3812 8.875Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
            </svg>
        </div>
        <div className="menu-item item-white" onClick={() => onNavigate("MyTeam")} >
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.0969 10.0867L9.09125 20.3201C8.73837 20.8287 7.90807 20.6003 7.90807 19.988L7.8977 14.1344C7.8977 13.4597 7.32687 12.9201 6.62112 12.9097L2.43848 12.8578C1.92992 12.8474 1.62895 12.3181 1.90917 11.9133L8.91481 1.67989C9.26769 1.17133 10.098 1.39967 10.098 2.01202L10.1084 7.86563C10.1084 8.54025 10.6792 9.07993 11.385 9.09031L15.5676 9.14219C16.0658 9.14219 16.3667 9.68189 16.0969 10.0867Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div className="menu-item item-blue" onClick={() => onNavigate("NeuroAudio")} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.40002 15.6V8.39999M12 21.6V2.39999M7.20002 18V5.99999M21.6 8.39999V15.6M16.8 5.99999L16.8 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div className="menu-item item-black" onClick={() => onNavigate("Payment")} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 12V11C11.4477 11 11 11.4477 11 12H12ZM12.0797 12H13.0797C13.0797 11.4477 12.632 11 12.0797 11V12ZM12.0797 12.0721V13.0721C12.632 13.0721 13.0797 12.6244 13.0797 12.0721H12.0797ZM12 12.0721H11C11 12.6244 11.4477 13.0721 12 13.0721V12.0721ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM15.5 12C15.5 13.933 13.933 15.5 12 15.5V17.5C15.0376 17.5 17.5 15.0376 17.5 12H15.5ZM12 15.5C10.067 15.5 8.5 13.933 8.5 12H6.5C6.5 15.0376 8.96243 17.5 12 17.5V15.5ZM8.5 12C8.5 10.067 10.067 8.5 12 8.5V6.5C8.96243 6.5 6.5 8.96243 6.5 12H8.5ZM12 8.5C13.933 8.5 15.5 10.067 15.5 12H17.5C17.5 8.96243 15.0376 6.5 12 6.5V8.5ZM12 13H12.0797V11H12V13ZM11.0797 12V12.0721H13.0797V12H11.0797ZM12.0797 11.0721H12V13.0721H12.0797V11.0721ZM13 12.0721V12H11V12.0721H13Z" fill="white"/>
            </svg>
        </div>
        <div className="menu-item item-black" onClick={() => onNavigate("Profile")} >
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.50876 1.83009L7.45828 7.14272L11 18.1699M11 18.1699L14.635 7.04951L12.6777 1.83009M11 18.1699L1.40002 7.23592M11 18.1699L20.6 7.23592M1.40002 7.23592L5.50099 1.83009H16.5923L20.6 7.23592M1.40002 7.23592H20.6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
      </div>
    )
  }
  
  export default Menu;