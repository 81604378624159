import React, {DragEvent, useEffect, useRef, useState} from 'react';
import '../styles/team.css'
import logo from "../image/image 31.png"
import DefaultImage from '../image/DefaultImg.svg'
import userEvent from '@testing-library/user-event';

const MyTeam: React.FC = () => {

    type TeamData = {
        levels: any; // Или string[], если уровни передаются как строки
        usersInOneLevel: number[];
        inviterUserPhoto: string | null;
        inviterUsername: string | null;
    };

    interface User {
        userPhoto: string;
        username: string;
    }

    interface Level {
        level: number;
        countOfusers: number;
    }

    const [teamData, setTeamData] = useState<TeamData>({
        levels: [],
        usersInOneLevel: [],
        inviterUserPhoto: null,
        inviterUsername: null,
    });

    const mockData = {
        inviterUserPhoto: "",
        inviterUsername: "",
        levels: [{countOfusers: 2, level: 1}, {countOfusers: 2, level: 2},
            {
                countOfusers: 2,
                level: 3
            }, {countOfusers: 2, level: 4}, {countOfusers: 2, level: 5}, {countOfusers: 2, level: 6}, {
                countOfusers: 2,
                level: 7
            }, {countOfusers: 7, level: 8}, {countOfusers: 2, level: 9}, {countOfusers: 2, level: 10}, {
                countOfusers: 2,
                level: 11
            },],
        usersInOneLevel: [{
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg', username: 'lis'},
        {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg', username: 'lis'},
        {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {
            userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg',
            username: 'lis'
        }, {userPhoto: 'https://i.pinimg.com/736x/c2/3e/f8/c23ef8a33c5cd0ccd734202c54546058.jpg', username: 'lis'}]
    }

    const [usersInOneLevel, setUsersInOneLevel] = useState<User[]>([]);
    const firstColumnData = teamData.levels.slice(0, Math.ceil(teamData.levels.length / 2));
    const secondColumnData = teamData.levels.slice(Math.ceil(teamData.levels.length / 2));
    const [currentPage, setCurrentPage] = useState(0); // Текущая страница
    const itemsPerPage = 10; // Количество друзей на странице
    const totalPages = Math.ceil(usersInOneLevel?.length / itemsPerPage);

    const diffX=useRef<number>(0);
    const startX=useRef<number>(0);

    const[left, setLeft]=useState<number>(0);

    useEffect(() => {
        const fetchFriendsData = async () => {

            try {
                // Замените URL на реальный API-эндпоинт
                const response = await fetch("https://otvy.ru/api/User/myTeam", {
                    method: "GET",
                    headers: {
                        Accept: "text/plain",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Ошибка сервера: ${response.statusText}`);
                }

                const data = await response.json();

                // Получение данных usersInOneLevel из ответа
                if (data?.data?.usersInOneLevel) {
                    setUsersInOneLevel(data.data.usersInOneLevel);
                } else {
                    console.error("Не удалось найти поле 'usersInOneLevel' в полученных данных.");
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
        };

        fetchFriendsData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://otvy.ru/api/User/myTeam", {
                    method: "GET",
                    headers: {
                        Accept: "text/plain",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Ошибка: ${response.status}`);
                }
                const result: { data: TeamData } = await response.json();
                setTeamData(result.data);
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            }
        };
        fetchData();
    }, []);


    function HandleDrag(e:React.TouchEvent<HTMLDivElement>)
    {
        diffX.current=startX.current-e.touches[0].clientX
        setLeft(-1*diffX.current);
    }

    function HandleDrop()
    {
        if (diffX.current>150 && currentPage!==totalPages-1)
        {
            setCurrentPage(currentPage+1);
        }
        if (diffX.current<-150 && currentPage!==0)
        {
            setCurrentPage(currentPage-1);
        }
        setLeft(0);
    }

    function HandlerDragStart(e:React.TouchEvent<HTMLDivElement>)
    {
        startX.current=e.touches[0].clientX
    }
    const telegram = window.Telegram.WebApp;

    const initDataUnsafe = telegram.initDataUnsafe;

    const paginatedFriends = usersInOneLevel?.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );
    

    return (
        <div className="teamInner">
            <div className="myTeam">
                <div className="myTeamTitle">
                    <span>МОЯ КОМАНДА</span>
                </div>
                <div className="myTeamLobby" style={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
                    <div className="row" style={{display: 'flex', gap: '20px'}}>
                        {/* Первая колонка */}
                        <div style={{flex: 1}}>
                            {firstColumnData.map((level: Level, index: number) => (
                                <div key={`row-${index}`} className="tab-container" style={{marginBottom: '10px'}}>
                                    <div className="tab tab-dark">{`${level.level || 0} ЛИНИЯ`}</div>
                                    <div className="tab tab-light">{`${level.countOfusers || 0} ЧЕЛОВЕК`}</div>
                                </div>
                            ))}
                        </div>
                        {/* Вторая колонка */}
                        <div style={{flex: 1}}>
                            {secondColumnData.map((level: Level, index: number) => (
                                <div key={`row-${index}`} className="tab-container" style={{marginBottom: '10px'}}>
                                    <div className="tab tab-dark">{`${level.level || 0} ЛИНИЯ`}</div>
                                    <div className="tab tab-light">{`${level.countOfusers || 0} ЧЕЛОВЕК`}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <div
                className="friends"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Разделить контент, чтобы inviter был снизу
                    height: "100%", // Установить высоту секции
                    minHeight: "400px", // Минимальная высота для предотвращения смещения
                    overflowX:'hidden' 
                }}
            >
                <div>
                    <div className="friendsTitle">
                        <span>{initDataUnsafe.user?.username || "@nousername"}</span>
                    </div>
                    <div style={{height: '200px',position:'relative', left:left+'px'}} draggable onTouchMove={(e)=>HandleDrag(e)} onTouchStart={(e) => HandlerDragStart(e)} onTouchEnd={()=> HandleDrop()}>
                        <div
                            className="listFriends"
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)", // Две колонки
                                gap: "10px",
                                overflowX: "auto", // Горизонтальная прокрутка
                                padding: "0 10px",
                            }}
                        >
                            {paginatedFriends.map((user, index) => (
                                <div key={index} className="friendBox">
                                    <svg
                                        style={{marginLeft: "7px"}}
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        <rect
                                            width="25"
                                            height="25"
                                            rx="2"
                                            fill={`url(#pattern${index})`}
                                        />
                                        <defs>
                                            <pattern
                                                id={`pattern${index}`}
                                                patternContentUnits="objectBoundingBox"
                                                width="1"
                                                height="1"
                                            >
                                                <use
                                                    xlinkHref={`#image${index}`}
                                                    transform="scale(0.005)"
                                                />
                                            </pattern>
                                            <image
                                                id={`image${index}`}
                                                width="200"
                                                height="200"
                                                xlinkHref={
                                                    user.userPhoto || "default-image-url"
                                                }
                                            />
                                        </defs>
                                    </svg>
                                    <div className="name">
                                        <span>@{user.username}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="slider"
                        style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "start",
                            paddingTop: "10px",
                        }}
                    >
                        {/* Точки пагинации */}
                        {Array.from({length: totalPages}).map((_, index) => (
                            <svg
                                key={index}
                                onClick={() => setCurrentPage(index)} // Переключение страниц
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{cursor: "pointer"}}
                            >
                                <circle
                                    cx="9"
                                    cy="9"
                                    r="9"
                                    fill={index === currentPage ? "white" : "#D9D9D9"}
                                    fillOpacity={index === currentPage ? "1" : "0.3"}
                                />
                            </svg>
                        ))}
                    </div>
                </div>
                <div
                    className="inviter"
                    style={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                    }}
                >
                    <div className="inviteText">
                        <span className="Boldinvite">МЕНЯ</span> ПРИГЛАСИЛИ
                    </div>
                    <div className="friend">
                        <div className="friendBox">
                            <svg
                                style={{marginLeft: "4px"}}
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                                <rect
                                    width="25"
                                    height="25"
                                    rx="2"
                                    fill="url(#pattern0_0_465)"
                                />
                                <defs>
                                    <pattern
                                        id="pattern0_0_465"
                                        patternContentUnits="objectBoundingBox"
                                        width="1"
                                        height="1"
                                    >
                                        <use
                                            xlinkHref="#image0_0_465"
                                            transform="scale(0.005)"
                                        />
                                    </pattern>
                                    <image
                                        id="image0_0_465"
                                        width="200"
                                        height="200"
                                        xlinkHref={
                                            teamData.inviterUserPhoto ||
                                            "default-image-url"
                                        }
                                    />
                                </defs>
                            </svg>
                            <div className="name">
                                <span>{teamData.inviterUsername || "@nousername"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default MyTeam;