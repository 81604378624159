import React, {useState} from 'react';
import {updateProfile, getUserProfile} from '../../api/userApi';


interface ProfileEditProps {
    initialProfile: any;
    onUpdateSuccess: (updatedProfile: any) => void;
    goToHome:any;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({initialProfile, onUpdateSuccess, goToHome}) => {
    const [profile, setProfile] = useState(initialProfile);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setProfile((prev: any) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            // Исключаем поле пригласившего при обновлении
            const {inviterLogin, ...updateData} = profile;
            const response = await updateProfile(updateData);

            if (response.success) {
                const updatedProfile = await getUserProfile();
                onUpdateSuccess(updatedProfile);
                setIsEditing(false);
            }
            for (var key in response.data)
            {
                alert(key);
            }
            goToHome();

        } catch (error) {
            console.error('Profile update failed', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Имя</label>
                <input
                    type="text"
                    name="firstName"
                    value={profile.firstName}
                    onChange={handleChange}
                    // disabled={!isEditing}
                />
            </div>
            <div>
                <label>Фамилия</label>
                <input
                    type="text"
                    name="lastName"
                    value={profile.lastName}
                    onChange={handleChange}
                    // disabled={!isEditing}
                />
            </div>
            <div>
                <label>Телефон</label>
                <input
                    type="tel"
                    name="phoneNumber"
                    value={profile.phoneNumber}
                    onChange={handleChange}
                    // disabled={!isEditing}
                />
            </div>
            <div>
                <label>Email</label>
                <input
                    type="email"
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    // disabled={!isEditing}
                />
            </div>

            {!isEditing ? (
                <button type="button" onClick={() => setIsEditing(true)}>
                    Редактировать
                </button>
            ) : (
                <>
                    <button type="submit">Сохранить</button>
                    <button type="button" onClick={() => setIsEditing(false)}>
                        Отмена
                    </button>
                </>
            )}
        </form>
    );
};

export default ProfileEdit;
