import React, { useEffect, useState } from 'react';
import './assets/styles/mainWindow.css';
import Home from './assets/components/home';
import Payment from './assets/components/payment';
import MyTeam from './assets/components/team';
import NeuroAudio from './assets/components/NeuroAudio';
import Menu from './assets/components/menu';
import Setting from './assets/components/settings';
import ProfileEdit from './assets/components/ProfileEdit';
import OrderConfirmation from './assets/components/OrderConfirmation';
import { login } from './api/userApi';
import MyProfile from "./assets/components/MyProfile";

interface Profile {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    // Add other fields as per your API responses.
}


const App: React.FC = () => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [currentScreen, setCurrentScreen] = useState("Home");
    const [showProfile, setShowProfile] = useState(false);
    const [profile, setProfile] = useState<Profile | null>(null);

    const [purchasedAudio, setPurchasedAudio]=useState<number|null>(null)

    const goToHome = () => setCurrentScreen("Home");
    const goToPayment = () => setCurrentScreen("Payment");
    const goToMyTeam = () => setCurrentScreen("MyTeam");
    const goToNeuroAudio = () => setCurrentScreen("NeuroAudio");
    const goToSetting = () => setCurrentScreen("Setting");

    const telegram = window.Telegram.WebApp;
    const initDataUnsafe = telegram.initDataUnsafe;


    useEffect(() => {
        // Делаем запрос на сервер
        fetch(`https://otvy.ru/api/Auth/login?Id=${initDataUnsafe?.user?.id}`, {
            method: 'GET',
            headers: {
                'accept': 'text/plain'
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    // Здесь нужно получить токен из ответа (предполагается, что сервер его возвращает)
                    response.json().then((data) => {
                        if (data.access_token) {
                            // Сохраняем токен в localStorage
                            localStorage.setItem("authToken", data.access_token);
                        }
                        goToHome();
                    });
                } else if (localStorage.getItem("authToken")) {
                    goToHome();
                } else {
                    goToSetting();
                }
            })
            .catch((error) => {
                console.error("Ошибка запроса:", error);
                goToSetting();
            });
    }, []);
// Пустой массив зависимостей, чтобы запрос выполнялся только один раз при монтировании компонента.

    const renderScreen = () => {
        switch (currentScreen) {
            case "Home":
                return <Home onNavigate={setCurrentScreen} profile={profile} />;
            case "Payment":
                return <Payment onNavigate={setCurrentScreen} setPurchasedAudio={setPurchasedAudio}  />;
            case "Confirm":
                return <OrderConfirmation purchasedAudio={purchasedAudio}  />;
            case "MyTeam":
                return <MyTeam />;
            case "NeuroAudio":
                return <NeuroAudio setCurrentScreen={setCurrentScreen}
                 setPurchasedAudio={setPurchasedAudio} />;
            case "Profile":
                return <MyProfile profile={profile} setProfileForEdit={setProfile} setCurrentScrren={setCurrentScreen}/>;
            case "Setting":
                return <Setting onNavigate={setCurrentScreen} showProfile={showProfile} />;
            case "ProfileEdit":
                return profile && (
                    <ProfileEdit
                        initialProfile={profile}
                        onUpdateSuccess={(updatedProfile) => {
                            setProfile(updatedProfile);
                            goToHome();
                        }}
                        goToHome={goToHome}
                    />
                );
            case "OrderConfirmation":
                return <OrderConfirmation purchasedAudio={purchasedAudio}  />;
            default:
                return <Home onNavigate={setCurrentScreen} profile={profile} />;
        }
    };

    const getBackgroundColor = () => {
        switch (currentScreen) {
            case "Home":
                return 'linear-gradient(179.98deg, #000000 0%, #212031 100%)';
            case "Payment":
                return 'linear-gradient(179.98deg, #2b2b3b 0%, #3a3a4b 100%)';
            case "MyTeam":
                return 'rgba(33, 32, 49, 1)';
            case "NeuroAudio":
                return 'linear-gradient(179.98deg, #212031 0%, #020202 100%)';
            case "Setting":
                return 'linear-gradient(179.98deg, #212031 0%, #020202 100%)';
            default:
                return 'rgba(33, 32, 49, 1)';
        }
    };

    return (
        <div className="Window">
            <div className="mainWindow" style={{ background: getBackgroundColor() }}>
                <div className="mainWindow_container" style={{ height: showProfile ? 'calc(100vh - 80px)' : '100vh' }}>
                    {renderScreen()}
                </div>
            </div>
            {localStorage.getItem("authToken") && <Menu onNavigate={setCurrentScreen} />}
        </div>
    );
};

export default App;
