import React, {useEffect, useState} from "react";
import "../styles/settings.css";
import {register} from "../../api/userApi";
import '../../App.css'
import DefaultImg from '../image/DefaultImg.svg'
import ProfileEdit from "./ProfileEdit";

interface SettingsProps {
    onNavigate: (screen: string) => void;
    showProfile: boolean;

}

const telegram = window.Telegram.WebApp;

const initDataUnsafe = telegram.initDataUnsafe;
const ScreenOne = ({
                       onNext,
                       setLang,
                       lang,
                       username = "@nickname",
                       avatar = DefaultImg,
                   }: {
    onNext: () => void;
    setLang: (value: string) => void;
    lang: string;
    username?: string;
    avatar?: string;
}) => (
    <div className="firstScreenInner">
        <div className="firstScreenUser">
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
        </div>
        <div className="firstScreenUnder">
            <select
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                className="firstScreenUnderButton"
                style={{height:'53px'}}
            >
                <option style={{backgroundColor: "#3F59C1"}} value="" disabled>
                    Выберите язык
                </option>
                <option style={{backgroundColor: "#3F59C1"}} value="English">
                    English
                </option>
                <option style={{backgroundColor: "#3F59C1"}} value="Russian">
                    Русский
                </option>
                <option style={{backgroundColor: "#3F59C1"}} value="Indonesian">
                    Indonesian
                </option>
            </select>
            <div className="firstScreenUnderButton_Down">
        <span style={{marginLeft: "29px"}}>
          ШАГ{" "}
            <span
                style={{fontSize: "16px", fontWeight: "700", lineHeight: "18px"}}
            >
            1 ИЗ 5
          </span>
        </span>
                {lang ? (
                    <button className="view buttonBack" onClick={onNext}>
                        ДАЛЕЕ
                    </button>
                ) : (
                    <button className="buttonBack" disabled>
                        ДАЛЕЕ
                    </button>
                )}
            </div>
        </div>
    </div>
);


const ScreenTwo = ({
                       onNext,
                       setFullName,
                       fullName
                   }: {
    onNext: () => void;
    setFullName: (value: string) => void;
    fullName: string
}) => (
    <div className="firstScreenInner">
        <div className="firstScreenUser">
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
            {/*      <span style={{fontSize: "10px", fontWeight: "400", lineHeight: "10px"}}>
             ПОДТВЕРЖДЕНИЕ
      </span>*/}
        </div>
        <div className="firstScreenUnder">
            <input
                onChange={(e) => setFullName(e.target.value)}
                className="firstScreenUnderButton"
                defaultValue={initDataUnsafe.user?.first_name || "unknown"}
                placeholder="Имя и Фамилия"
                style={{
                    padding: "29px",
                    color: "#fff",
                    boxSizing: "border-box",
                }}
            />
            <div className="firstScreenUnderButton_Down">
        <span style={{marginLeft: "29px"}}>
          ШАГ{" "}
            <span
                style={{fontSize: "16px", fontWeight: "700", lineHeight: "18px"}}
            >
            2 ИЗ 5
          </span>
        </span>
                {100 > 1 ?
                    <button className={`${100 > 0 ? 'view' : 'hidden'} buttonBack`} onClick={onNext}>
                        ДАЛЕЕ
                    </button>
                    :
                    <button className={`${100 < 2 ? 'view' : 'hidden'} buttonBack`} disabled>
                        ДАЛЕЕ
                    </button>
                }
            </div>
        </div>
    </div>
);


const ScreenTree = ({onNext, setTime, time}: {
    onNext: () => void,
    setTime: (value: string) => void;
    time: string
}) => (
    <div className='firstScreenInner'>
        <div className='firstScreenUser'>
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
            <span style={{fontSize: '10px', fontWeight: "400", lineHeight: '10px'}}>ПОДТВЕРЖДЕНИЕ</span>
        </div>
        <div className='firstScreenUnder'>
            <input
                onChange={(e) => setTime(e.target.value)}
                className='firstScreenUnderButton'
                placeholder='Номер телефона'
                style={{
                    padding: '29px',
                    color: '#fff',
                    boxSizing: 'border-box'
                }}
            />
            {/* <span style={{marginLeft: '29px'}}>ВАШ EMAIL</span> */}
            {/* <img style={{width: '18px', height: '18px', marginRight: '17px'}} src={sckrepka} alt="" /> */}
            <div className='firstScreenUnderButton_Down'>
                <span style={{marginLeft: '29px'}}>ШАГ <span
                    style={{fontSize: '16px', fontWeight: '700', lineHeight: '18px'}}>3 ИЗ 5</span></span>
                {time.length > 5 ?
                    <button className={`${time.length > 5 ? 'view' : 'hidden'} buttonBack`} onClick={onNext}>
                        ДАЛЕЕ
                    </button>
                    :
                    <button className={`${time.length < 7 ? 'view' : 'hidden'} buttonBack`} disabled>
                        ДАЛЕЕ
                    </button>
                }
            </div>
        </div>
    </div>
);

const ScreenFour = ({onNext, setPhone, phone}: {
    onNext: () => void,
    setPhone: (value: string) => void;
    phone: string
}) => (
    <div className='firstScreenInner'>
        <div className='firstScreenUser'>
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
            {/*<span style={{fontSize: '10px', fontWeight: "400", lineHeight: '10px'}}>ПОДТВЕРЖДЕНИЕ</span>*/}
        </div>
        <div className='firstScreenUnder'>
            <input
                onChange={(e) => {
                    setPhone(e.target.value);
                }}
                className='firstScreenUnderButton'
                placeholder='Номер телефона'
                style={{
                    padding: '29px',
                    color: '#fff',
                    boxSizing: 'border-box'
                }}
            />
            {/* <span style={{marginLeft: '29px'}}>ВАШ EMAIL</span> */}
            {/* <img style={{width: '18px', height: '18px', marginRight: '17px'}} src={sckrepka} alt="" /> */}
            <div className='firstScreenUnderButton_Down'>
                <span style={{marginLeft: '29px'}}>ШАГ <span
                    style={{fontSize: '16px', fontWeight: '700', lineHeight: '18px'}}>3 ИЗ 5</span></span>
                {phone.length > 5 ?
                    <button className={`${phone.length > 5 ? 'view' : 'hidden'} buttonBack`} onClick={onNext}>
                        ДАЛЕЕ
                    </button>
                    :
                    <button className={`${phone.length < 7 ? 'view' : 'hidden'} buttonBack`} disabled>
                        ДАЛЕЕ
                    </button>
                }
            </div>
        </div>
    </div>
);

const ScreenFive = ({onNext, setShare}: { onNext: () => void, setShare: (value: string) => void }) => (
    <div className='firstScreenInner'>
        <div className='firstScreenUser'>
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
            {/*<span style={{fontSize: '10px', fontWeight: "400", lineHeight: '10px'}}>ПОДТВЕРЖДЕНИЕ</span>*/}
        </div>
        <div className='firstScreenUnder'>
            <input
                onChange={(e) => {
                    setShare(e.target.value);
                }}
                className='firstScreenUnderButton'
                placeholder='Логин пригласившего (опционально)'
                style={{
                    padding: '29px',
                    color: '#fff',
                    boxSizing: 'border-box'
                }}
            />
            <div className='firstScreenUnderButton_Down'>
                <span style={{marginLeft: '29px'}}>ШАГ <span
                    style={{fontSize: '16px', fontWeight: '700', lineHeight: '18px'}}>4 ИЗ 5</span></span>
                <button className='buttonBack' onClick={onNext}>ДАЛЕЕ</button>
            </div>
        </div>
    </div>
);

const ScreenSix = ({onNext, setWallet}: { onNext: () => void, setWallet: (value: string) => void }) => (
    <div className='firstScreenInner'>
        <div className='firstScreenUser'>
            <div className="firstScreenLogo">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <rect
                        width="48"
                        height="48"
                        rx="2"
                        fill="url(#pattern0_1_1111)"
                    />
                    <defs>
                        <pattern
                            id="pattern0_1_1111"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                        </pattern>
                        <image
                            id="image0_1_1111"
                            width="200"
                            height="200"
                            xlinkHref={initDataUnsafe.user?.photo_url}
                        />
                    </defs>
                </svg>
                <span>{initDataUnsafe.user?.username || "@nousername"}</span>
            </div>
            {/*<span style={{fontSize: '10px', fontWeight: "400", lineHeight: '10px'}}>ПОДТВЕРЖДЕНИЕ</span>*/}
        </div>
        <div className='firstScreenUnder'>
            <input
                onChange={(e) => {
                    setWallet(e.target.value);
                }}
                className='firstScreenUnderButton'
                placeholder='Адрес кошелька (опционально)'
                style={{
                    padding: '29px',
                    color: '#fff',
                    boxSizing: 'border-box'
                }}
            />
            <div className='firstScreenUnderButton_Down'>
                <span style={{marginLeft: '29px'}}>ШАГ <span
                    style={{fontSize: '16px', fontWeight: '700', lineHeight: '18px'}}>5 ИЗ 5</span></span>
                <button className='buttonBack' onClick={onNext}>ДАЛЕЕ</button>
            </div>
        </div>
    </div>
);

const ScreenLast = ({wallet, share, phone, reg, lang}: {
    wallet: string;
    share: string;
    phone: string;
    lang: string;
    // time:string;
    // email:string;
    // fullName:string
    reg: () => void;
}) => {
    const telegram = window.Telegram.WebApp;
    const initDataUnsafe = telegram.initDataUnsafe;
    const [userData, setUserData] = useState<any>(null);

    const Regis = () => {
            // Проверяем, доступна ли WebApp
            if (window.Telegram && window.Telegram.WebApp) {
                const telegram = window.Telegram.WebApp;

                // Получаем данные пользователя из initDataUnsafe
                const initData = telegram.initDataUnsafe;

                if (initData && initData.user) {
                    const user = {
                        id: String(initData.user.id), // Преобразуем в строку
                        firstName: initData.user.first_name || "",
                        username: initData.user.username || "", // Если username нет, будет пустая строка
                        authDate: new Date().toISOString()  // Текущая дата
                    };

                    setUserData(user);

                    // Далее можно отправить эти данные на сервер
                    registerUser(user); // Здесь вызываем функцию для регистрации
                }
            }
    }
    // Функция для отправки данных на сервер
    const registerUser = async (user: { id: string; firstName: string; username: string; authDate: string }) => {
        try {
            const {id, firstName, username, authDate} = user;

            // Формируем URL с параметрами
            const url = new URL("https://otvy.ru/api/Auth/register");
            url.searchParams.append("Id", id);
            url.searchParams.append("First_name", firstName);
            url.searchParams.append("Username", username);
            url.searchParams.append("Auth_date", authDate);

            const response = await fetch(url.toString(), {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                },
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(`Ошибка при регистрации: ${response.status}`);
            }

            if (result.access_token) {
                localStorage.setItem("authToken", result.access_token);
            } else {
                throw new Error("Ответ не содержит корректный токен");
            }
        } catch (error) {
            console.error("Ошибка при выполнении запроса:", error);
        }
    };

    let language = "RU"

    if (lang == "Indonesian") {
        language = "IN "
    }
    if (lang == "English") {
        language = "EN "
    }
    if (lang == "Russian") {
        language = "RU "
    }

    return (
        <div className="secondScreenInner">
            <div className="profile">
                <div className="profileLogo">
                    <div className="profileLogoInner">
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <rect
                                width="48"
                                height="48"
                                rx="2"
                                fill="url(#pattern0_1_1111)"
                            />
                            <defs>
                                <pattern
                                    id="pattern0_1_1111"
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                    height="1"
                                >
                                    <use xlinkHref="#image0_1_1111" transform="scale(0.005)"/>
                                </pattern>
                                <image
                                    id="image0_1_1111"
                                    width="200"
                                    height="200"
                                    xlinkHref={initDataUnsafe.user?.photo_url}
                                />
                            </defs>
                        </svg>
                        <span>{initDataUnsafe.user?.username || "@nousername"}</span>
                    </div>
                    <span style={{marginRight: "26px"}}>СТАТУС</span>
                </div>
                <div className="profileUnder">
                    {/* <div className="profileUnderRow">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "10px",
                  marginLeft: "23px",
                }}
              >
                {time || 'undefined yet'}
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "10px",
                  marginRight: "23px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                ДАТУ РОЖДЕНИЯ
              </span>
            </div>
          </div> */}
                    <div className="profileUnderRow">
                        <div>
              <span
                  style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginLeft: "23px",
                  }}
              >
                {phone || 'undefined yet'}
              </span>
                        </div>
                        <div>
              <span
                  style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginRight: "23px",
                      color: "rgba(255, 255, 255, 0.5)",
                  }}
              >
                PHONE NUMBER
              </span>
                        </div>
                    </div>
                    <div className="profileUnderRow">
                        <div>
              <span
                  style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginLeft: "23px",
                  }}
              >
                {share || 'undefined yet'}
              </span>
                        </div>
                        <div>
              <span
                  style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginRight: "23px",
                      color: "rgba(255, 255, 255, 0.5)",
                  }}
              >
                SHARED PERSON
              </span>
                        </div>
                    </div>
                    <div className="profileUnderRow">
                        <div>
              <span
                  style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginLeft: "23px",
                  }}
              >
                {wallet || 'undefined yet'}
              </span>
                        </div>
                        <div>
              <span
                  style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "10px",
                      marginRight: "23px",
                      color: "rgba(255, 255, 255, 0.5)",
                  }}
              >
                WALLET ADDRESS
              </span>
                        </div>
                    </div>
                    {/* <div className="profileUnderRow">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "10px",
                  marginLeft: "23px",
                }}
              >
                {fullName }
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "10px",
                  marginRight: "23px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                USERNAME
              </span>
            </div>
          </div> */}
                    {/* <div className="profileUnderRow">
            <span
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "10px",
                marginLeft: "23px",
              }}
            >
              {email}
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "10px",
                marginRight: "23px",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
             EMAIL
            </span>
          </div> */}
                    <div className="profileUnderRow">
            <span
                style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "10px",
                    marginLeft: "23px",
                }}
            >
              ЯЗЫК
            </span>
                        <span
                            style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "10px",
                                marginRight: "23px",
                                color: "rgba(255, 255, 255, 0.5)",
                            }}
                        >
              <span
                  style={{color: "rgba(255, 255, 255, 1)"}}>{language}</span>{lang ? lang : "Русский"}
            </span>
                    </div>
                    <button className='buttonBack' onClick={() => { reg(); Regis(); }}>REGISTER</button>
                </div>
            </div>
        </div>
    );
};

const Setting: React.FC<SettingsProps> = ({onNavigate, showProfile}) => {
    const [lang, setLang] = useState("");
    const [fullName, setFullName] = useState("");
    const [time, setTime] = useState("");
    const [share, setShare] = useState('')
    const [phone, setPhone] = useState('')
    const [wallet, setWallet] = useState('')
    const [currentScreen, setCurrentScreen] = useState(showProfile ? 5 : 1);

    const handleNext = () => {
        setCurrentScreen(currentScreen + 1);
    };
    const handleReg = async () => {
        await register({
            lang,
            fullName,
            phone,
            wallet
        });

        onNavigate("home");
    };

    interface Profile {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        // Add other fields as per your API response.
    }

    return (
        <div>
            {currentScreen === 1 && (
                <ScreenOne onNext={handleNext} lang={lang} setLang={setLang}/>
            )}
            {currentScreen === 2 && (
                <ScreenTwo onNext={handleNext} fullName={fullName} setFullName={setFullName}/>
            )}
            {/* {currentScreen === 3 && fullName.length > 0 && (
        <ScreenTree onNext={handleNext} time={time} setTime={setTime} />
      )} */}
            {currentScreen === 3 && (
                <ScreenFour onNext={handleNext} phone={phone} setPhone={setPhone}/>
            )}
            {currentScreen === 4 && phone.length > 0 && (
                <ScreenFive onNext={handleNext} setShare={setShare}/>
            )}
            {currentScreen === 5 && (
                <ScreenSix onNext={handleNext} setWallet={setWallet}/>
            )}
            {currentScreen === 6 &&
                <ScreenLast wallet={wallet} lang={lang} share={share} phone={phone} reg={handleReg}/>}
        </div>
    );
};

export default Setting;
