import React, {ReactElement, useEffect, useRef, useState} from "react";
import "../styles/NeuroAudio.css";
import toggleIcon from "../image/Vector.png";
import playButton from "../image/Group 49.png";
import PauseButton from "../image/Pause.png"

//audio example - "https://www.computerhope.com/jargon/m/example.mp3"

interface DropdownProps {
    title: string;
    content: ReactElement;
    blockNumber: number;
    extraClass?: string;
}

interface NeuroAudioProps {
    setCurrentScreen: (screen: string) => void;
    setPurchasedAudio:any;
}


interface AudioData {
    index: number;
    audio: string;
    purchased: boolean;
    purchaseStatus: string;
    usernameToPurchase: string;
    walletToPurchase: string;
}

interface AudioFCProps
{
    index:number;
    audiosrc:string;
    currentAudioIndexPlaying:number|null;
    setCurrentAudioIndexPlaying:any;
    isAtLeastOneAudioPlaying:boolean;
    setIsAtLeastOneAudioPlaying:any;
}

const Audio:React.FC<AudioFCProps>=({index, audiosrc, currentAudioIndexPlaying, setCurrentAudioIndexPlaying, isAtLeastOneAudioPlaying, setIsAtLeastOneAudioPlaying})=>
{
    function onPlayPause()
    {
        if(isAtLeastOneAudioPlaying && currentAudioIndexPlaying!==index)
        {
            return;
        }
        const playProcess=audioElement.current?.play();
        setIsAtLeastOneAudioPlaying(true);
        setCurrentAudioIndexPlaying(index)
        if(playProcess!==undefined)
        {
            playProcess.then(_=>
            {
                if(isPlaying)
                {
                    audioElement.current?.pause()
                    setIsAtLeastOneAudioPlaying(false);
                    setCurrentAudioIndexPlaying(null);
                }
            }
            )
            .catch(_=>
            {
                return;
            }
            )
        }
        setIsPlaying(!isPlaying);
        
    }

    function onUpdatingDuration()
    {
        setDuration(audioElement.current?.currentTime)
        if (audioElement.current?.currentTime===audioElement.current?.duration)
        {
            setIsPlaying(false);
            setDuration(0);
            setIsAtLeastOneAudioPlaying(false);
            setCurrentAudioIndexPlaying(null);
        }
    }
    function onChangeDuration(duration:number)
    {
        setDuration(duration)
        audioElement.current && (audioElement.current.currentTime = duration);
        
    }
    const[isButtonPressed, setIsButtonPressed]=useState<boolean>(false);
    const[isPlaying,setIsPlaying]=useState<boolean>(false);
    const[duration, setDuration]=useState<number|undefined>(0);

    const audioElement=useRef<HTMLAudioElement>(null);
    

    return(
        <>
        {isButtonPressed?
        <div style={{marginBottom:'8px', display:'flex'}}>
            <img height={'30px'} src={isPlaying?PauseButton:playButton} style={{marginRight:'5px'}} onClick={()=> onPlayPause()}/>
            <div style={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
                <input type="range" min={0} max={audioElement.current?.duration} value={duration} onChange={(e)=> onChangeDuration(parseInt(e.target.value))}/>
                <audio ref={audioElement} preload="auto" onTimeUpdate={onUpdatingDuration}>
                    <source src={audiosrc}
                            type="audio/mpeg"/>
                </audio>
            </div>
        </div>:
            <button style={{backgroundColor:'transparent',  borderColor:'white' ,borderRadius:'30px 30px 30px 30px', color:'white', height:'40px', width:'145px', marginBottom:'8px'}} onClick={()=> setIsButtonPressed(true)}>Слушать</button>
            }
        </>
    )
}

const Dropdown: React.FC<DropdownProps> = ({title, content, blockNumber, extraClass}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown-block">
            <div className={`dropdown-header ${isOpen ? "open" : ""}`} onClick={toggleDropdown}>
                <span className="block-number">{blockNumber}</span>
                <span className="block-title">{title}</span>
                <button className="toggle-button" aria-label="Toggle dropdown">
                    <img src={toggleIcon} alt={isOpen ? "Collapse" : "Expand"}/>
                </button>
            </div>
            {isOpen && <div className={`dropdown-content ${extraClass}`}>{content}</div>}
        </div>
    );
};

const NeuroAudio: React.FC<NeuroAudioProps> = ({ setCurrentScreen, setPurchasedAudio }) => {
    const [audioData, setAudioData] = useState<AudioData[]>([]);
    const[isAtLeastOneAudioPlaying, setIsAtLeastOneAudioPlaying]=useState<boolean>(false)
    const[currentAudioIndexPlaying, setCurrentAudioIndexPlaying]=useState<number|null>(null)
    useEffect(() => {
        // Загрузка данных с сервера
        const fetchAudioData = async () => {
            try {
                const response = await fetch("https://otvy.ru/api/User/Audio", {
                    method: 'GET',
                    headers: {
                        Accept: "text/plain",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Ошибка загрузки данных");
                }

                const result = await response.json();


                // Сохраняем данные в состояние, если они есть
                if (result?.data) {
                    setAudioData(result.data);
                }
                alert(audioData.length);
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
        };

        fetchAudioData();
    }, []);

    const onPurchase=(purchasedAudio:number)=>
    {
        setPurchasedAudio(purchasedAudio);
        setCurrentScreen('Payment')
    }


    const cardCount = 7; // Фиксированное количество карточек
    const staticCards = Array.from({length: cardCount}, (_, index) => {
        const data = audioData.find((item) => item.index - 1 === index);// Находим данные по индексу
        return (
            <div key={index} className="DropdownInner">
                <Dropdown
                    title={
                        index === 0
                            ? "ОСОЗНАННОЕ МЫШЛЕНИЕ"
                            : index === 1
                                ? "ФИНАНСОВОЕ БЛАГОПОЛУЧИЕ"
                                : `НОВЫЙ УРОВЕНЬ - ${index + 1}`
                    }
                    // Добавляем +1 для корректного отображения номера
                    blockNumber={index + 1} // Отображение индекса начинается с 1
                    extraClass="linear"
                    content={
                        data ? (
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span className="neuroAudioText">Description - {index + 1}</span>
                                {data.purchased ? (
                                    // Показываем плеер
                                    <div style={{marginTop: "10px", width: "100%"}}>
                                        <Audio 
                                        index={index}
                                        audiosrc={data.audio}
                                        currentAudioIndexPlaying={currentAudioIndexPlaying}
                                        setCurrentAudioIndexPlaying={setCurrentAudioIndexPlaying} 
                                        isAtLeastOneAudioPlaying={isAtLeastOneAudioPlaying} 
                                        setIsAtLeastOneAudioPlaying={setIsAtLeastOneAudioPlaying}/>
                                    </div>
                                ) : (
                                    // Показываем кнопку "Купить"
                                    <button
                                        style={{marginTop: "5px", marginBottom: "8px"}}
                                        className="PayButton"
                                        onClick={() => onPurchase(index+1)} // Добавьте обработчик события покупки
                                    >
            <span style={{fontSize: "12px", fontWeight: "400", lineHeight: "18px"}}>
                {
                    index === 0
                        ? "ОПЛАТИТЬ 5 USDT"
                        : index === 1
                            ? "ОПЛАТИТЬ 7 USDT"
                            : index === 2
                                ? "ОПЛАТИТЬ 10 USDT"
                                : index === 3
                                    ? "ОПЛАТИТЬ 29 USDT"
                                    : index === 4
                                        ? "ОПЛАТИТЬ 39 USDT"
                                        : index === 5
                                            ? "ОПЛАТИТЬ 43 USDT"
                                            : index === 6
                                                ? "ОПЛАТИТЬ 56 USDT"
                                                : "Не продаётся"
                }
            </span>
                                    </button>
                                )}
                            </div>

                        ) : (
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span className="neuroAudioText">Нет данных</span>
                            </div>
                        )
                    }
                />
            </div>
        );
    });

    return <div className="DropdownContainer">{staticCards}</div>;
};

export default NeuroAudio;