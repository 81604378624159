import axios from 'axios';
import { path } from './path';

const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [key, value] = cookie.trim().split('=');
      if (key === name) {
        return decodeURIComponent(value);
      }
    }
    return null;
};

export const login = async () => {
    if (window.Telegram?.WebApp) {
        const telegram = window.Telegram.WebApp;

        const initDataUnsafe = telegram.initDataUnsafe;

        if (initDataUnsafe?.user) {
            try {
                const response = await axios.get(`${path}/Auth/login?Id=${initDataUnsafe.user.id.toString()}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'text/plain'
                    },
                });
                    
                if (response.data?.access_token) {
                    document.cookie = `accessToken=${response.data.access_token}`;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                }  
    
                getUserProfile();
                
                return response.data;
        
            } catch (err) {
                console.error('Ошибка при авторизации', err);
            }
        } else {
            console.error("User data not found in Telegram WebApp context");
        }
    } else {
        console.error("Telegram WebApp SDK not available");
    }
    return null;
}

export const register = async (data: any) => {
    if (window.Telegram?.WebApp) {
        const telegram = window.Telegram.WebApp;

        const initDataUnsafe = telegram.initDataUnsafe;

        const newData = {
            Id: initDataUnsafe.user?.id,
            First_name: initDataUnsafe.user?.first_name,
            Last_name: initDataUnsafe.user?.last_name,
            Username: initDataUnsafe.user?.username,
            Photo_url: initDataUnsafe.user?.photo_url,
            Auth_date: initDataUnsafe.auth_date,
            Hash: initDataUnsafe.hash,
            Phone: data.phone,
            Email: data.email,
            InviterLogin: data.username,
            Language: initDataUnsafe.user?.language_code,
            CryptoWalletAddress: data.wallet,
        }

        if (initDataUnsafe?.user) {
            try {
                const response = await axios.get(`${path}/Auth/register`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'text/plain'
                    },
                    params: newData,
                });

                if (response.data?.access_token) {
                    document.cookie = `accessToken=${response.data.access_token}; path=/; secure; SameSite=Strict;`;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                }

                await getUserProfile();
        
                return response.data;
        
            } catch (err) {
                console.error('Ошибка при авторизации', err);
            }
        } else {
            console.error("User data not found in Telegram WebApp context");
        }
    } else {
        console.error("Telegram WebApp SDK not available");
    }
    return null;
}

export const getUserProfile = async () => {
    const token = getCookie('accessToken');
    try {
        const response = await axios.get(`${path}/User/profile`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain',
                'Authorization': `Bearer ${token}`,
            },
        });

        return response.data;

    } catch (err) {
        console.error('Ошибка получения профиля', err);
    }
    return null;
};

export const updateProfile = async (profileData: any) => {
    try {
        const token = getCookie('accessToken');
        const response = await axios.post(`${path}/register`, profileData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return { success: true, data: response.data };
    } catch (error) {
        console.error('Profile update error', error);
        return { success: false, error };
    }
};

export const getMyTeam = async () => {
    try {
        const token = getCookie('accessToken');
        const response = await axios.get(`${path}/myTeam`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fetch team error', error);
        throw error;
    }
};

export const getUserAudio = async () => {
    try {
        const token = getCookie('accessToken');
        const response = await axios.get(`${path}/User/Audio`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fetch audio error', error);
        throw error;
    }
};

export const confirmOrder = async (orderData: any) => {
    try {
        const token = getCookie('accessToken');
        const response = await axios.post(`${path}/Order/Confirm`, orderData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return { success: true, data: response.data };
    } catch (error) {
        console.error('Order confirmation error', error);
        return { success: false, error };
    }
};
